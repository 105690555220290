.body {
  margin: 0px;
  padding: 0px;
  background-color: #f8f8f8;
  min-height: 100vh;
}

.bgImage {
  width: 100%;
  background-image: url('https://images-front-soukw.s3.amazonaws.com/bgSouKW.jpg');
}

.cardLogin {
  width: 450px;
  height: 550px;
  display: 'flex';
  background: rgba(255, 255, 255, 0.65);
  flex-direction: 'row';
  align-items: center;
  border-radius: 15px;
}

.buttonLogin {
  font-size: 18px;
  color: #FFFFFF;
  border: transparent;
  border-radius: 25px;
  height: 48px;
  width: 300px;
  text-align: center;
  background-image: linear-gradient(to right, rgba(24, 133, 157, 0.9), rgba(24, 98, 188, 1));
  cursor: pointer;

  :hover {
    background-image: linear-gradient(to left, rgba(24, 133, 157, 0.9), rgba(24, 107, 156, 1));
  }
}

h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #373f51;
  font: Muli, sans-serif;
  margin: 0%;
}

h6 {
  font-weight: 400;
  margin: 0%;
  font-size: 18px;
  color: #373f51;
  font: Muli, sans-serif;
}

.navbar {
  background-color: #282b33;
  height: 64px;
  width: 100%;
}

.navbar-box {
  width: 75px;
  margin-right: 1.25rem;
  height: 100%;
  background-color: #ce011f;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  display: inline-block;
  cursor: pointer;
}

.navbar-logo {
  width: 25px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 22px;
}

.navbar-item {
  display: inline-block;
  color: white;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}

.sidebar {
  box-shadow: 1px 0 20px 0 rgb(0 0 0 / 10%);
  width: 75px;
  height: 100%;
  text-align: center;
  position: sticky;
}

.sidebar-box {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.selected {
  background-color: #e8f4f6;
}

.sidebar-icon {
  text-align: center;
  width: 35px;
  height: 35px;
}

.content {
  margin-top: 35px;
}

.btn {
  min-width: 120px;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: .8rem;
  padding-bottom: .8rem;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background .3s, border-color .3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.btnPrimary {
  color: #fff;
  background: #01707B;
  border-color: #01707B;
}

.btnSecondary {
  color: #1C5CBC;
  background: white;
  border-color: #1C5CBC;
}

.textFieldForm {
  margin-top: 50px;
  margin-bottom: 5px;
}

.card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 #d7d9dc;
}

.btnForgotPass {
  color: #fff;
  background: #0099a7;
  border-color: #0099a7;
  min-width: 34.89rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: .8rem;
  padding-bottom: .8rem;
  font-size: 18px;
  line-height: 16px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background .3s, border-color .3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.btnReturnLogin {
  padding-top: 1.5rem;
  cursor: pointer;
  Color: #0099a7;
  border: none;
  background-color: transparent;
}

.imGrid {
  display: flex;
  flex-direction: row;
}

.imCard {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background: #FFF;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
  height: 10rem;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.imLogo {
  position: relative;
  bottom: 8%;
  right: 2px;
  border-radius: 6px 0px 0px 6px;
  height: 12rem;
  width: 16rem;
}

.dataLandInfo {
  position: relative;
  top: 30px;
}

.eemovelInfo {
  position: relative;
  top: 20px;
  line-height: 12px;
}

.imH1 {
  font-size: 20px;
  font-weight: bold;
}

.imP {
  font-size: 12px;
}